import { render, staticRenderFns } from "./SpotlightSupplierTable.vue?vue&type=template&id=648184b1&scoped=true&"
import script from "./SpotlightSupplierTable.vue?vue&type=script&lang=js&"
export * from "./SpotlightSupplierTable.vue?vue&type=script&lang=js&"
import style0 from "./SpotlightSupplierTable.vue?vue&type=style&index=0&id=648184b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "648184b1",
  null
  
)

export default component.exports