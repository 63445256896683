<template>
  <div>
    <!-- Supplier header -->
    <div class="spaced-out py-2 px-3 supplier-header border">
      <div>
        <router-link
          v-if="isAdmin && supplierData.supplierId"
          :to="{
            name: 'supplier-details',
            params: { supplierId: supplierData.supplierId }
          }"
          target="_blank"
        >
          <strong>{{ supplierName }}</strong>
        </router-link>
        <strong v-else>{{ supplierName }}</strong>
        <b-button
          v-if="isAdmin"
          variant="light"
          class="scroll-btn border ml-2"
          size="sm"
          v-b-tooltip.hover="'Scroll to next supplier'"
          @click="scrollToNextSupplier(spotlightGroup.name, supplierName)"
        >
          <font-awesome-icon :icon="['far', 'chevron-double-down']" class="text-muted" />
        </b-button>
      </div>
      <div class="d-flex align-items-center">
        <b-badge variant="primary" class="border">
          {{ $currencySymbol }}{{ formatNumber(supplierData.amount) }}
          <span v-if="isAdmin">spend</span>
        </b-badge>
        <b-badge class="ml-2 border" variant="warning">
          {{ invoices.length }} <span v-if="isAdmin">invoices</span>
        </b-badge>
        <b-badge class="ml-2 border" variant="success">
          {{ supplierData.attachmentsCount }}
          <font-awesome-icon class="ml-1" :icon="['fas', 'paperclip']" />
        </b-badge>
        <b-badge v-if="supplierData.ooc && isAdmin" class="ml-2 border" variant="danger">
          OOC
        </b-badge>
      </div>
    </div>

    <b-table class="mb-3" responsive :items="invoices" :fields="fields">
      <template v-slot:cell(exportedInvoice)="row">
        <b-badge
          variant="auto"
          v-if="row.value"
          v-b-tooltip="'Invoice attachment details were used to create a supply in SE'"
          :id="row.item.invoiceId"
          >AP</b-badge
        >
      </template>
      <template v-slot:cell(newInvoice)="row">
        <b-badge class="newBadge" v-if="row.value">New</b-badge>
      </template>
      <template v-slot:cell(HEAD_attachments)="data">
        <font-awesome-icon :icon="['fas', 'paperclip']" :title="data.label" />
      </template>
      <template v-slot:cell(attachments)="row">
        <AttachmentButton :row="row" />
      </template>
      <template v-slot:cell(createdDate)="row">{{ formatDate(row.value) }}</template>
      <template v-slot:cell(dueDate)="row">{{ formatDate(row.value) }}</template>
      <template v-slot:cell(createdInReducer)="row">
        {{ formatDate(row.value) }}
        <b-badge v-if="row.item.manualUpload" variant="primary" class="p-1 ml-1"
          >Manual Upload</b-badge
        >
      </template>
      <template v-slot:cell(lineItemDescriptions)="row">
        <span v-html="max150(row.value)" />
      </template>
      <template v-slot:cell(contractEndDate)="row"
        >{{ formatDate(getEndDate(row.item.billDetails)) }}

        <b-badge v-if="isOOC(row.item.billDetails)" class="mr-2" variant="danger"> OOC </b-badge>

        <b-badge v-if="isFinalBill(row.item.billDetails)" variant="secondary"> Final Bill </b-badge>
      </template>
      <template v-slot:cell(showContractDetails)="row">
        <b-badge v-if="row.item.flag" variant="primary">
          {{ row.item.flag }}
        </b-badge>

        <b-button
          v-else-if="row.value && isAdmin"
          @click="row.toggleDetails"
          class="border d-block"
          variant="light"
          size="sm"
          >Attachment details
          <b-badge v-if="row.item.billDetails && row.item.billDetails.length > 1" variant="primary">
            {{ row.item.billDetails.length }}
          </b-badge>

          <b-badge
            v-if="isDomesticBill(row.item.billDetails) && isAdmin"
            variant="secondary"
            class="my-1 d-block"
            v-b-popover.hover="'Organisation with domestic bill template. To be manually reviewed.'"
            >Domestic bill</b-badge
          >
        </b-button>
      </template>
      <template #row-details="row">
        <div v-for="(bill, i) in row.item.billDetails" :key="i">
          <SpotlightInvoiceDetails :bill="bill" />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import AttachmentButton from "@/components/AttachmentButton";
import SpotlightInvoiceDetails from "@/components/spotlight/SpotlightInvoiceDetails";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SpotlightSupplierTable",
  components: { AttachmentButton, SpotlightInvoiceDetails },
  props: {
    invoices: Array,
    supplierName: String,
    supplierData: Object,
    isAdmin: Boolean
  },
  computed: {
    fields() {
      let fields = [
        // { key: "invoiceId", label: "", class: "text-center" },
        { key: "newInvoice", label: "New", class: "text-center" },
        { key: "attachments", label: "Attachments", class: "text-center" },
        { key: "customerName", label: "Name" },
        { key: "subAmount", label: "Amount exc tax" },
        { key: "taxAmount", label: "Tax" },
        { key: "amount", label: "Amount inc tax" },
        { key: "currencyCode", label: "Currency" },
        {
          key: "createdDate",
          label: "Issued"
        },
        {
          key: "createdInReducer",
          label: "Added to Reducer"
        },
        { key: "lineItemDescriptions", label: "Details" }
      ];
      if (this.isAdmin) {
        fields.push(
          { key: "contractEndDate", label: "Contract End" },
          { key: "showContractDetails", label: "" }
        );
        fields.unshift({ key: "exportedInvoice", label: "", class: "text-center" });
      }
      return fields;
    }
  },
  methods: {
    formatNumber: FormatHelper.formatNumberToDisplay,
    formatDate: FormatHelper.formatDate,
    max150: FormatHelper.max150,
    isDomesticBill(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.domesticBill) {
            return true;
          }
        }
      }
    },

    isOOC(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.outOfContract) {
            return true;
          }
        }
      }
    },

    getEndDate(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.contractEndDate) {
            return bill.contractEndDate;
          }
        }
      }
    },

    isFinalBill(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.finalBill) {
            return true;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@include badge-styles;
.supplier-group {
  &:last-child {
    .scroll-btn {
      display: none;
    }
  }
}
.supplier-header {
  background: $color-grey-lighter3;
  strong {
    font-size: 14px;
  }
  .btn {
    font-size: 12px;
  }
}
.badge-auto {
  background-color: $color-blue-darker2;
  color: white !important;
}
</style>
