<template>
  <div id="spotlight">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Spotlight</h1>
      <div v-if="isAdmin" class="d-flex flex-row-reverse">
        <b-btn
          class="mb-2"
          variant="outline-secondary"
          target="_blank"
          href="https://reducr.atlassian.net/wiki/spaces/OP/pages/875659265/Spotlight+Rule+Writing"
          >Rules and Tips</b-btn
        >
      </div>

      <h4>{{ orgShortName(organisation.name) }}</h4>
      <div v-if="isLoading">
        <Busy primary text="Loading..." />
      </div>

      <div v-else-if="spotlightGroups.length > 0">
        <!-- Spotlight area collapse list -->
        <b-list-group v-for="spotlightGroup in spotlightGroups" :key="spotlightGroup.name">
          <b-list-group-item
            @click="toggleCollapse(spotlightGroup.name)"
            href="#"
            class="spaced-out"
          >
            {{ spotlightGroup.name }}

            <div class="d-flex">
              <b-badge variant="primary" class="ml-2">
                {{ $currencySymbol }}{{ formatNumber(spotlightGroup.amount) }}
                <span v-if="isAdmin">spend</span>
              </b-badge>
              <b-badge class="ml-2" variant="warning">
                {{ spotlightGroup.invoices.length }} <span v-if="isAdmin">invoices</span>
              </b-badge>
              <b-badge v-if="isAdmin" variant="secondary" class="ml-2">
                {{ spotlightGroup.supplierData.supplierCount }}
                {{ spotlightGroup.supplierData.supplierCount > 1 ? "suppliers" : "supplier" }}
              </b-badge>
              <b-badge class="ml-2" variant="success">
                {{ spotlightGroup.attachmentsCount }}
                <font-awesome-icon class="ml-1" :icon="['fas', 'paperclip']" />
              </b-badge>
              <b-badge v-if="spotlightGroup.ooc && isAdmin" class="ml-2" variant="danger">
                OOC
              </b-badge>
            </div>
          </b-list-group-item>
          <b-collapse :id="spotlightGroup.name" class="border">
            <!-- Order by meter number > supplier for Energy invoices -->
            <template v-if="spotlightGroup.name == 'Energy'">
              <div v-for="(data, meterNumber) in spotlightGroup.meterInvoices" :key="meterNumber">
                <div
                  class="meter-header py-2 px-3 pointer border"
                  @click="toggleCollapse(meterNumber)"
                >
                  Meter number <strong>{{ data.fullMeterNumber }}</strong>
                </div>
                <b-collapse :id="meterNumber" visible>
                  <b-list-group
                    v-for="(invoices, supplier) in data.supplierInvoices"
                    :key="supplier"
                    :id="spotlightGroup.name + supplier + meterNumber"
                    class="supplier-group"
                  >
                    <SpotlightSupplierTable
                      :invoices="invoices"
                      :supplier-name="supplier"
                      :supplier-data="spotlightGroup.supplierData[supplier]"
                      :is-admin="isAdmin"
                      @scroll-to-next="
                        scrollToNextSupplier(spotlightGroup.name, supplier, meterNumber)
                      "
                    />
                  </b-list-group>
                </b-collapse>
              </div>
            </template>
            <!-- Order by supplier for other groups -->
            <template v-else>
              <b-list-group
                v-for="(invoices, supplier) in spotlightGroup.supplierInvoices"
                :key="supplier"
                :id="spotlightGroup.name + supplier"
                class="supplier-group"
              >
                <SpotlightSupplierTable
                  :invoices="invoices"
                  :supplier-name="supplier"
                  :supplier-data="spotlightGroup.supplierData[supplier]"
                  :is-admin="isAdmin"
                  @scroll-to-next="scrollToNextSupplier(spotlightGroup.name, supplier)"
                />
              </b-list-group>
            </template>
          </b-collapse>
        </b-list-group>
      </div>
      <div v-else>{{ message }}</div>
    </b-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import Console from "@/console";
import Busy from "@/components/Busy";
import CognitoAuth from "@/cognito/cognitoauth";
import TypeHelper from "@/helper/typehelper";
import SpotlightSupplierTable from "@/components/spotlight/SpotlightSupplierTable.vue";

export default {
  name: "spotlight",
  components: { Busy, SpotlightSupplierTable },
  data() {
    return {
      title: "Spotlight",
      isLoading: true,
      message: null,
      isAdmin: false,
      organisation: {},
      spotlightGroups: []
    };
  },
  created() {
    this.fetchData();
    this.refreshAdmin();
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    formatNumber: FormatHelper.formatNumberToDisplay,
    async refreshAdmin() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
    },
    async fetchData() {
      const organisationId = this.$route.params.organisationId;
      this.fetchSpotlight(organisationId);
      this.fetchOrganisation(organisationId);
    },
    async fetchOrganisation(organisationId) {
      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        Console.log(response);

        if (response.data.status === "success") {
          this.organisation = response.data.organisation;
        } else {
          this.showWarning("There was a problem getting the organisation.");
        }
      } catch {
        this.showWarning("There was a problem getting the organisation.");
      }
    },
    async fetchSpotlight(organisationId) {
      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}invoices?organisationId=${organisationId}&includeSpotlight=true&itemsPerPage=${
            ApiHelper.ALL_ITEMS
          }&includeNewFlag=true`
        );

        Console.log(response);

        if (response.data.status === "success") {
          this.message = null;

          if (response.data.invoices.length > 0) {
            var invoiceMap = ApiHelper.toMap(response.data.invoices, (i) => i.invoiceId);

            // Loop through spotlight groups and create a summary, like
            // attachment count, existing suppliers, has OOC bill...
            for (let g of response.data.spotlightGroups) {
              g.amount = 0;
              g.attachmentsCount = 0;
              g.supplierInvoices = {};
              g.meterInvoices = {};
              g.supplierData = {
                supplierCount: 0
              };
              g.ooc = false;
              g.finalBill = false;

              // Loop through spotlight group invoices
              for (let i = 0; i < g.invoices.length; i++) {
                g.invoices[i] = invoiceMap[g.invoices[i].invoiceId];
                g.invoices[i].amount = g.invoices[i].amount ? g.invoices[i].amount : 0;
                g.amount += g.invoices[i].amount;

                const supplierName = g.invoices[i].supplierName
                  ? g.invoices[i].supplierName
                  : g.invoices[i].customerName
                  ? g.invoices[i].customerName
                  : "Unknown";

                g.invoices[i].name = supplierName;

                this.markAsExported(g.name, g.invoices[i]);

                // Populate Energy invoices (by meter number)
                if (g.name == "Energy") {
                  // Use bottom line of meter number only
                  let meterNumber = "not found";
                  if (
                    g.invoices[i].billDetails &&
                    g.invoices[i].billDetails[0] &&
                    g.invoices[i].billDetails[0].uniqueMeterNumber
                  ) {
                    meterNumber = g.invoices[i].billDetails[0].uniqueMeterNumber;
                  }

                  if (!g.meterInvoices[meterNumber]) {
                    g.meterInvoices[meterNumber] = { supplierInvoices: {} };
                    if (meterNumber != "not found") {
                      g.meterInvoices[meterNumber].fullMeterNumber =
                        g.invoices[i].billDetails[0].meterNumber;
                    }
                  }
                  if (g.meterInvoices[meterNumber].supplierInvoices[supplierName] != undefined) {
                    g.meterInvoices[meterNumber].supplierInvoices[supplierName].push(g.invoices[i]);
                  } else {
                    g.meterInvoices[meterNumber].supplierInvoices[supplierName] = [g.invoices[i]];
                    g.supplierData.supplierCount += 1;
                  }
                  // Populate invoices for other suppliers invoices
                } else {
                  if (g.supplierInvoices[supplierName] != undefined) {
                    g.supplierInvoices[supplierName].push(g.invoices[i]);
                  } else {
                    g.supplierInvoices[supplierName] = [g.invoices[i]];
                    g.supplierData.supplierCount += 1;
                  }
                }

                if (!g.supplierData[supplierName]) {
                  // Populate supplier data
                  g.supplierData[supplierName] = {
                    amount: 0,
                    attachmentsCount: 0,
                    supplierId: g.invoices[i].supplierId,
                    ooc: false,
                    finalBill: false
                  };
                }

                g.supplierData[supplierName].amount += g.invoices[i].amount;

                if (g.invoices[i].attachments) {
                  g.attachmentsCount++;
                  g.supplierData[supplierName].attachmentsCount += 1;
                }

                if (i == g.invoices.length - 1) {
                  g.supplierInvoices = Object.keys(g.supplierInvoices)
                    .sort()
                    .reduce((obj, key) => {
                      obj[key] = g.supplierInvoices[key];
                      return obj;
                    }, {});
                }

                if (g.invoices[i].outOfContract) {
                  g.ooc = true;
                  g.supplierData[supplierName].ooc = true;
                }

                if (g.invoices[i].finalBill) {
                  g.finalBill = true;
                  g.supplierData[supplierName].finalBill = true;
                }

                g.invoices[i].showContractDetails = this.hasContractDetails(g.invoices[i]);
                g.invoices[i].domesticBillOrganisation = this.isOrgDomesticBill(g.invoices[i]);
              }

              if (g.meterInvoices["not found"]) {
                let invs = g.meterInvoices["not found"];
                delete g.meterInvoices["not found"];
                g.meterInvoices["not found"] = invs;
              }
            }

            response.data.spotlightGroups.sort((a, b) => {
              let indexA = TypeHelper.spotlightSectors.indexOf(a.name);
              let indexB = TypeHelper.spotlightSectors.indexOf(b.name);
              return indexA > -1 ? indexA - indexB : 0;
            });
            this.spotlightGroups = response.data.spotlightGroups;
          } else {
            this.message = "This Organisation has no Spotlight data.";
          }
        } else {
          this.showWarning(
            (this.message = "There was a problem getting Spotlight data for this Organisation.")
          );
        }
      } catch (e) {
        Console.log(e);
        this.showWarning(
          (this.message = "There was a problem getting Spotlight data for this Organisation.")
        );
      } finally {
        this.isLoading = false;
      }
    },

    scrollToNextSupplier(...params) {
      const el = document.getElementById(params.join()).nextSibling;
      if (el) el.scrollIntoView();
    },

    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },

    async toggleCollapse(id) {
      await this.$nextTick();
      this.$root.$emit("bv::toggle::collapse", id);
    },

    async scrollToExportedInvoice(group) {
      await this.$nextTick();
      this.toggleCollapse(group);
      await this.$nextTick();
      let els = document.getElementsByClassName("badge-auto");
      if (els.length) setTimeout(() => els[0].scrollIntoView(), 200);
    },

    markAsExported(group, invoice) {
      if (this.$route.query.exp && this.$route.query.exp.includes(invoice.invoiceId)) {
        invoice.exportedInvoice = true;
        invoice._rowVariant = "exp";
        this.scrollToExportedInvoice(group);
      }
    },

    isOrgDomesticBill(invoice) {
      if (
        invoice.domesticBill == true &&
        typeof invoice.billingAddress != "undefined" &&
        invoice.billingAddress != null
      )
        return true;
      return false;
    },

    hasContractDetails(invoice) {
      if (invoice.billDetails && invoice.billDetails.length > 0) {
        return true;
      }

      return false;
    }
  }
};
</script>

<style lang="scss">
#spotlight {
  @import "@/styles/common.scss";
  @include badge-styles;
  .container {
    max-width: 110rem;
  }
  .newBadge {
    background-color: $color-pink-main;
    color: white;
  }
  .spaced-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .meter-header {
    background: $color-grey-lighter3 !important;
    font-size: 15px;
    color: $color-font-headings;
  }
  #Energy {
    .supplier-header {
      background: $color-grey-lighter10 !important;
    }
  }
  .table-exp td {
    background-color: #903bbf2e !important;
  }
}
</style>
