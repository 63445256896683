<template>
  <div>
    <div class="contract-details">
      <div :class="'w-100 px-3 py-2 header ' + (bill.supplyType ? bill.supplyType : 'NULL')">
        <div class="header-left d-flex">
          <div v-if="bill.supplyType == 'GAS'" :class="'mr-2 icon-holder ' + bill.supplyType">
            <font-awesome-icon :icon="['far', 'fire-alt']" size="lg" />
          </div>
          <div
            v-else-if="bill.supplyType == 'ELECTRICITY'"
            :class="'mr-2 icon-holder ' + bill.supplyType"
          >
            <font-awesome-icon :icon="['far', 'bolt']" size="lg" />
          </div>
          <div v-else class="mr-2 icon-holder">
            <font-awesome-icon :icon="['fas', 'question']" size="lg" />
          </div>

          <p v-if="bill.supplyType">{{ bill.supplyType }}</p>
          <p v-else>Unknown</p>

          <div class="doc-conf ml-2" v-if="bill.confidenceScore">
            <p v-if="bill.confidenceScore > 98" class="m-0 p-0">| Great file quality</p>
            <p v-else-if="bill.confidenceScore > 95" class="m-0 p-0">| Good file quality</p>
            <p v-else-if="bill.confidenceScore > 90" class="m-0 p-0">| Average file quality</p>
            <p v-else-if="bill.confidenceScore > 85" class="m-0 p-0">| Poor file quality</p>
            <p v-else-if="bill.confidenceScore > 80" class="m-0 p-0">| Bad file quality</p>
            <p v-else-if="bill.confidenceScore <= 80" class="m-0 p-0">| Very bad file quality</p>
          </div>
        </div>

        <div class="header-right">
          <b-badge v-if="bill.confidenceScoreFound" class="ml-2 score-badge">
            {{ bill.confidenceScoreFound }}% processor confidence
          </b-badge>

          <div class="header-flags" v-if="bill.flags && bill.flags.length > 0">
            <b-badge v-for="flag in bill.flags" :key="flag" class="ml-2 flag-badge">
              {{ flag }}
            </b-badge>
          </div>
        </div>
      </div>

      <b-row class="body px-3">
        <b-col
          :cols="bill.supplyType != 'GAS' ? '3' : '4'"
          class="contract-details-box py-3 line-right"
        >
          <div class="text-space line-under">
            <p><strong>Created Date: </strong></p>
            <p v-if="bill.createdDate">{{ formatDate(bill.createdDate) }}</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Account Number:</strong></p>
            <p v-if="bill.accountNumber">{{ bill.accountNumber }}</p>
            <p v-else>--</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Meter Number:</strong></p>
            <p v-if="bill.meterNumber">{{ bill.meterNumber }}</p>
            <p v-else>--</p>
          </div>

          <div :class="'text-' + (bill.organisationAddress ? 'title' : 'space')">
            <p><strong>Organisation address:</strong></p>
            <p v-if="bill.organisationAddress">{{ bill.organisationAddress }}</p>
            <p v-else>--</p>
          </div>
        </b-col>

        <b-col
          :cols="bill.supplyType != 'GAS' ? '3' : '4'"
          class="contract-details-box py-3 line-right"
        >
          <div
            class="text-space line-under"
            v-if="
              bill.standingCharge != null ||
              (bill.standingCharge == null &&
                (bill.estimatedStandingCharge == null || bill.estimatedStandingCharge == 0))
            "
          >
            <p><strong>Standing Charge: </strong></p>
            <p v-if="bill.standingCharge != null">{{ formatNumber(bill.standingCharge) }} p/day</p>
            <p v-else>--</p>
          </div>

          <div
            class="text-space line-under"
            v-if="
              bill.standingCharge == null &&
              bill.estimatedStandingCharge != null &&
              bill.estimatedStandingCharge > 0
            "
          >
            <p>
              <strong>Standing Charge:</strong>
              <b-badge variant="secondary">Estimated </b-badge>
            </p>
            <p>{{ bill.estimatedStandingCharge }} p/day</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Day Rate:</strong></p>
            <p v-if="bill.dayRate != null">{{ formatNumber(bill.dayRate) }} p/day</p>
            <p v-else>--</p>
          </div>
          <div class="text-space line-under">
            <p><strong>Day Consumption:</strong></p>
            <p v-if="bill.dayConsumption != null">{{ formatNumber(bill.dayConsumption) }} kWh</p>
            <p v-else>--</p>
          </div>

          <div v-if="bill.supplyType == 'GAS'">
            <div class="text-space line-under">
              <p>
                <strong>Day Annual Consumption </strong>
                <b-badge v-if="bill.dayAnnualConsumptionCalculated" variant="secondary"
                  >Estimated
                </b-badge>
                <b-badge v-else-if="bill.dayAnnualConsumptionCalculated == false" variant="primary"
                  >Extracted
                </b-badge>
              </p>
              <p v-if="bill.dayAnnualConsumption != null">
                {{ formatNumber(bill.dayAnnualConsumption) }} kWh
              </p>
              <p v-else>--</p>
            </div>

            <div class="text-space">
              <p>
                <strong>Total Annual Consumption </strong>
                <b-badge v-if="bill.totalAnnualConsumptionCalculated" variant="secondary"
                  >Estimated
                </b-badge>
                <b-badge
                  v-else-if="bill.totalAnnualConsumptionCalculated == false"
                  variant="primary"
                  >Extracted
                </b-badge>
              </p>
              <p v-if="bill.totalAnnualConsumption != null">
                {{ formatNumber(bill.totalAnnualConsumption) }} kWh
              </p>
              <p v-else>--</p>
            </div>
          </div>

          <div v-if="bill.supplyType == 'ELECTRICITY'">
            <div class="text-space line-under">
              <p><strong>Night Rate:</strong></p>
              <p v-if="bill.nightRate != null">{{ formatNumber(bill.nightRate) }} p/day</p>
              <p v-else>--</p>
            </div>
            <div class="text-space line-under">
              <p><strong>Night Consumption:</strong></p>
              <p v-if="bill.nightConsumption != null">
                {{ formatNumber(bill.nightConsumption) }} kWh
              </p>
              <p v-else>--</p>
            </div>

            <div class="text-space line-under">
              <p><strong>EW Rate:</strong></p>
              <p v-if="bill.ewRate != null">{{ formatNumber(bill.ewRate) }} p/day</p>
              <p v-else>--</p>
            </div>
            <div class="text-space">
              <p><strong>EW Consumption:</strong></p>
              <p v-if="bill.ewConsumption != null">{{ formatNumber(bill.ewConsumption) }} kWh</p>
              <p v-else>--</p>
            </div>
          </div>
        </b-col>

        <b-col
          v-if="bill.supplyType != 'GAS'"
          cols="3"
          class="contract-details-box py-3 line-right"
        >
          <div class="text-title line-under">
            <p>
              <strong>Day Annual Consumption </strong>
              <b-badge v-if="bill.dayAnnualConsumptionCalculated" variant="secondary"
                >Estimated
              </b-badge>
              <b-badge v-else-if="bill.dayAnnualConsumptionCalculated == false" variant="primary"
                >Extracted
              </b-badge>
            </p>
            <p v-if="bill.dayAnnualConsumption != null">
              {{ formatNumber(bill.dayAnnualConsumption) }} kWh
            </p>
            <p v-else>--</p>
          </div>

          <div :class="'text-title line-under'">
            <p>
              <strong>Night Annual Consumption </strong>
              <b-badge v-if="bill.nightAnnualConsumptionCalculated" variant="secondary"
                >Estimated
              </b-badge>
              <b-badge v-else-if="bill.nightAnnualConsumptionCalculated == false" variant="primary"
                >Extracted
              </b-badge>
            </p>
            <p v-if="bill.nightAnnualConsumption != null">
              {{ formatNumber(bill.nightAnnualConsumption) }} kWh
            </p>
            <p v-else>--</p>
          </div>

          <div class="text-title line-under">
            <p>
              <strong>EW Annual Consumption </strong>
              <b-badge v-if="bill.ewAnnualConsumptionCalculated" variant="secondary"
                >Estimated
              </b-badge>
              <b-badge v-else-if="bill.ewAnnualConsumptionCalculated == false" variant="primary"
                >Extracted
              </b-badge>
            </p>
            <p v-if="bill.ewAnnualConsumption != null">
              {{ formatNumber(bill.ewAnnualConsumption) }} kWh
            </p>
            <p v-else>--</p>
          </div>

          <div class="text-title">
            <p>
              <strong>Total Annual Consumption </strong>
              <b-badge v-if="bill.totalAnnualConsumptionCalculated" variant="secondary"
                >Estimated
              </b-badge>
              <b-badge v-else-if="bill.totalAnnualConsumptionCalculated == false" variant="primary"
                >Extracted
              </b-badge>
            </p>
            <p v-if="bill.totalAnnualConsumption != null">
              {{ formatNumber(bill.totalAnnualConsumption) }} kWh
            </p>
            <p v-else>--</p>
          </div>
        </b-col>

        <b-col :cols="bill.supplyType != 'GAS' ? '3' : '4'" class="contract-details-box py-3">
          <div class="text-space line-under">
            <p><strong>Billing period from:</strong></p>
            <p v-if="bill.billingPeriodFrom">{{ formatDate(bill.billingPeriodFrom) }}</p>
            <p v-else>--</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Billing period to:</strong></p>
            <p v-if="bill.billingPeriodTo">{{ formatDate(bill.billingPeriodTo) }}</p>
            <p v-else>--</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Billing period over no. of days:</strong></p>
            <p v-if="bill.billingPeriodNoDays != null">{{ bill.billingPeriodNoDays }}</p>
            <p v-else>--</p>
          </div>

          <div class="text-space line-under">
            <p><strong>Billing period over no. of months:</strong></p>
            <p v-if="bill.billingPeriodNoMonths != null">{{ bill.billingPeriodNoMonths }}</p>
            <p v-else>--</p>
          </div>

          <div :class="'text-' + (bill.billingAddress ? 'title' : 'space')">
            <p><strong>Billing address:</strong></p>
            <p v-if="bill.billingAddress">{{ bill.billingAddress }}</p>
            <p v-else>--</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SpotlightInvoiceDetails",
  props: {
    bill: Object
  },
  methods: {
    formatNumber: FormatHelper.formatFloatToDisplay,
    formatDate: FormatHelper.formatDate,
    isDomesticBill(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.domesticBill) {
            return true;
          }
        }
      }
    },

    isOOC(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.outOfContract) {
            return true;
          }
        }
      }
    },

    getEndDate(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.contractEndDate) {
            return bill.contractEndDate;
          }
        }
      }
    },

    isFinalBill(bills) {
      if (bills && bills.length > 0) {
        for (const bill of bills) {
          if (bill.finalBill) {
            return true;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@include badge-styles;

.contract-details {
  margin: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 15px;
  background-color: $color-grey-lighter11;

  .header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-font-headings;
    .header-left {
      .icon-holder {
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-lighter7;

        &.GAS {
          background-color: $color-blue-lighter6;
        }
        &.ELECTRICITY {
          background-color: $color-pink-lighter5;
        }

        & > svg {
          color: $color-font-headings;
          padding: 0.05rem;
        }
      }

      & > p {
        margin: 0.05em 0 0 0;
        font-weight: bolder;
        color: white !important;
      }

      .doc-conf {
        display: flex;
        align-items: center;
        p {
          font-size: 12px !important;
          color: $color-grey-lighter1 !important;
        }
      }
    }

    .header-right {
      display: flex;
      .flag-badge {
        background-color: $color-grey-lighter2 !important;
      }

      .score-badge {
        background-color: $color-pink-lighter5 !important;
      }
    }
  }

  .body {
    p {
      color: $color-font-headings !important;
    }

    .contract-details-box {
      &.line-right {
        border-right: 1px solid $color-grey-lighter2;
      }

      .line-under {
        border-bottom: 1px solid $color-grey-lighter2;
      }

      .text-space {
        display: flex;
        justify-content: space-between;

        & > p {
          margin: 10px 0 10px 0;
        }
      }

      .text-title {
        & > p {
          margin: 10px 0 10px 0;
        }
      }
    }
  }
}
</style>
